

















import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'MultipleChoice',
  components: {
    Tablet
  },
  props: {
    questions: Array,
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    },
    stage: String,
    document: {
      type: Object,
      default () {
        return {
          href: '',
          label: ''
        }
      }
    }
  },
  data () {
    return {
      lines: [],
      shuffled: [],
      currentQuestion: 0,
      correct:   0,
      incorrect: 0,
    }
  },
  computed: {
    ...mapGetters([
      'stageAnswers',
      'stageHints',
      'hintsRemaining'
    ]),
    currentQuestionOptions () {
      const options = []
      let o: number, l: number
      if (this.currentQuestion>=0 && this.currentQuestion<this.shuffled.length) {
        for (o=0, l=this.shuffled[this.currentQuestion].options.length; o<l; o++) {
          options.push({
            text:  this.shuffled[this.currentQuestion].options[o],
            value: this.shuffled[this.currentQuestion].options[o]
          })
        }
      }
      return options
    },
    currentQuestionAnswer () {
      return (this.currentQuestion>=0 && this.currentQuestion<this.shuffled.length) ? this.shuffled[this.currentQuestion].answer : null
    },
    correctAnswer () {
      return (this.stageAnswers.hasOwnProperty(this.stage)) ? this.stageAnswers[this.stage] : 'What?'
    },
    hintsAvailable () {
      return (this.stageHints.hasOwnProperty(this.stage)) ? this.stageHints[this.stage] : []
    },
    quizDone () {
      return (this.currentQuestion === this.shuffled.length)
    }
  },
  methods: {
    ...mapActions([
      'loseHint'
    ]),
    show () {
      console.log('MultipleChoice.show')
      this.$refs.tablet.show()
      this.firstQuestion()
    },
    hide () {
      this.$refs.tablet.hide()
    },
    addLines (lines) {
      this.lines = [...this.lines, ...lines]
      this.$nextTick(()=>{
        this.$refs.console.scrollTop = 9999999
      })
    },
    ucFirst (string) {
      return string[0].toUpperCase() + string.substring(1)
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array
    },
    firstQuestion () {
      if (this.currentQuestion===0 && this.currentQuestion<this.shuffled.length) {
        this.addLines([{text: this.shuffled[this.currentQuestion].question, class: 'question'}])
      }
    },
    nextQuestion () {
      this.currentQuestion++
      // Show next question, if available.
      if (this.currentQuestion<this.shuffled.length) {
        this.addLines([{text: this.shuffled[this.currentQuestion].question, class: 'question'}])
        return
      }
      // No more questions
      // this.addLines([
      //   {text: 'End of quiz.', class: ''},
      //   {text: `Your score: ${this.correct} correct, ${this.incorrect} incorrect`, class: 'correct'}
      // ])
      this.addLines([
        {text: 'Congratulations! Click "Done" to complete this stage.', class: ''},
      ])
    },
    answer (answer) {
      if (answer === this.currentQuestionAnswer) {
        this.addLines([{text: answer + " -- Correct", class: 'correct'}])
        if (this.shuffled[this.currentQuestion].hasOwnProperty('info') && this.shuffled[this.currentQuestion].info) {
          this.addLines([{text: this.shuffled[this.currentQuestion].info, class: 'info'}])
        }
        this.correct++
      } else {
        this.addLines([{text: answer + " -- Incorrect", class: 'incorrect'}])
        this.incorrect++;
        this.shuffled.push(this.shuffled[this.currentQuestion])
      }
      console.log(`${this.correct} correct, ${this.incorrect} incorrect`)
      this.nextQuestion()
    },
    requestAssistance () {

    },
    completeExercise () {
      this.$emit('complete')
    }
  },
  mounted () {
    console.log('MultipleChoice.mounted')
    this.active = this.startActive
    this.shuffled = this.shuffleArray(this.questions)
  }
});
